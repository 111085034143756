.topNav {
  background: rgb(238 172, 249);
}

.hero {
  min-height: 60vh;
  background: linear-gradient(
    178.67deg,
    #7a87fb 1.11%,
    rgba(15, 39, 254, 0.54) 35.44%,
    rgba(228, 26, 208, 0.34) 98.87%
  );
}

/* .hero img {
  max-width: 400px;
} */

.group {
  position: fixed;
  bottom: 30px;
  right: 20px;
  font-size: 3rem;
}

.main-layout {
  min-height: 70vh;
}

.skills {
  max-width: 68.75rem;
  margin: auto;
  text-align: center;
  
  margin-top: 2.5rem;
}

.skill-header {
  margin-bottom: 1rem;
}

.skills-wrapper div {
  padding: 1.25rem;
}

.icons {
  font-size: 1.5rem;
  width: 15rem;
  height: 11.25rem;
  display: inline;
  align-items: center;
  /* justify-content: space-between; */
  /* flex-wrap: wrap; */
}

.icons div {
  margin-bottom: 2rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 10px rgb(106, 100, 233);
  /* align-items: center; */
}

.icons i {
  font-size: 6.5rem;
}

i.fa-figma {
  background: linear-gradient(
    180deg,
    #ee1414 0%,
    #d012d3 38.02%,
    #120eb6 66.15%,
    #14b70e 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}

.shitaaal:hover {
  transform: scale(1.1);
  /* transform: rotate(-360deg); */
  transition: all 0.9s ease-out;
  box-shadow: 0px 3px 10px rgb(220, 52, 68);
  /* transition: width 2s ease-in-out 1s; */
  margin-left: 13px;
}
.slct::first-letter {
  font-size: 100px;
}

i.fa-react {
  color: #508bf2;
}

i.fa-node {
  color: #89b087;
}

/* Projects */

.projects {
  background-color: var(--bg-color);
  padding: 32px 0;
  margin-top: 2rem;
}

.project-pic {
  width: 100%;
  height: 60%;
  border-radius: 95px;
}

.project-pic:hover {
  border-radius: 10;
  transform: scale(1.07);
  filter: blur(5px);
  opacity: 0.7;
  text-align: center;
}


.projects-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.6s ease;
}

.project-container:hover {
  border-radius: 95px;
  text-transform: inherit;
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
  opacity: 1;
    transform: translateY(-50%);
}

.projects-title {
  text-align: center;
  margin-bottom: 1rem;
}

.project-container {
  text-align: center;
  width: 21.875rem;
  padding: 1rem;
}

.project-container p {
  padding: 0.4rem;
}

.project-title {
  margin-bottom: var(--bottom-margin);
}

.project-details {
  margin-bottom: var(--bottom-margin);
  transform:translateY(100%);
  opacity: 0;
  transition: opacity 300ms, transform 450ms ease;
  color: white;
  max-width: 550px;
}

.project-card {
  background-color: #fff;
  border-radius: 11px;
  box-shadow: 0 3px 10px rgba(95, 67, 237, 0.824);
  padding: 20px;
  margin: 10px;
}

.effectForLink {
  background-image: linear-gradient(
    to right,
    #54b3d6,
    #54b3d6 50%,
    #5b05effe 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.effectForLink:before {
  content: "";
  background: #54b3d6;
  display: block;
  position: absolute;

  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.effectForLink:hover {
  background-position: 0;
}

.effectForLink:hover::before {
  width: 100%;
}

.more-about {
  background-color: var(--bg-color);
  padding: 1rem 6rem;
}

.more-about h2 {
  margin-bottom: var(--bottom-margin);
  text-align: center;
}

.more-about p {
  line-height: var(--line-height);
  padding: 0.4rem;
}

.contact {
  margin-top: 2rem;
}

.contact h2 {
  text-align: center;
  /* margin-bottom: var(--bottom-margin-2); */
  margin-bottom: 10px;
}

.contact-form-container {
  max-width: 40.75rem;
  margin: 0 auto;
  padding: 0.938rem;
  border-radius: 5px;
  /* box-shadow: 0 3px 10px 3px; */
  /* box-shadow: 0 3px 10px var(--secondary-shadow); */
  box-shadow: 0px 9px 30px rgba(255, 149, 5, 0.3);
}

.contact-form-container label {
  line-height: 2.7em;
  font-weight: var(--bold-font);
  color: var(--primary-color);
}

.contact-form-container textarea {
  min-height: 6.25rem;
  font-size: 14px;
}

.socials {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 1%;
  bottom: 50%;
}

.socicon {
  width: 2rem;
  height: 2rem;
}

@media screen and (max-width: 720px) {
  nav {
    padding: 1.5rem 1rem;
  }
  .projects-container {
    flex-direction: column;
  }

  .project-container {
    width: 20.875rem;
  }
  .icon {
    width: 5.875rem;
    height: 5.25rem;
  }
  .contact-form-container {
    max-width: 23.75rem;
  }
}

/* project */

.project_img {
  width: 100%;
  transition: all 600ms ease;
}

.project:hover .project_img {
  transform: scale(1.07);
  filter: blur(5px);
}

.project_wrapper {
  position: relative;
  border-radius: 20px;
  box-shadow: 0 20px 80px 0 rgb(0, 0, 0, 0.45);
  overflow: hidden;
  margin-bottom: 50px;
}

.project_wrapper:hover .project_description {
  opacity: 1;
  transform: translateY(-50%);
}

.project_wrapper:hover .project_img {
  transform: scale(1.07);
  filter: blur(5px);
}

.project_description {
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(100%);
  opacity: 0;
  transition: opacity 300ms, transform 460ms ease;
  color: white;
  max-width: 550px;
}

.project_wrapper:hover .project_wrapper--bg {
  opacity: 0.7;
}

.project_wrapper--bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1c1d25;
  opacity: 0;
  transition: all 450ms ease;
}

.project_description--title {
  font-size: 40px;
}
.project_description--sub-title {
  margin-top: 8px;
}

.project_description--links {
  color: white;
  font-size: 20px;
  margin-right: 16px;
}

.project_description--para {
  margin: 16px 0;
}

/* addes css */
.project-card {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-description {
  position: absolute;
  top: 50%;
  left:40px;
  /* width: 100%; */
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  opacity: 0;
  transition: opacity 0.3s;
}

.project-card:hover .project-description {
  opacity: 1;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  justify-items: center;
}

/* added */
.project-card {
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: transform 0.3s;
}

.project-card:hover {
  transform: scale(1.05);
  fill: blur(5px);

}

 .project-card:hover .project-description {
  /* opacity: 1; */
  transform: translateX(-50%);
  margin-left: 20px;
}

.project-pic {
  width: 100%;
  height: auto;
  display: block;
}


.project-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.8); */
  background-color: #1c1d25;
  color: white;
  padding: 15px;
  opacity: 1;
  transition: all  0.3s ease;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.project-description-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  margin-left: 20px;
}

.project-description-sub-title {
  font-size: 1rem;
  color: #888;
  margin-bottom: 0.5rem;
}

.project-description-para {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.project-description-links {
  margin-top: auto;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.project-description-link {
  color: #fff;
  font-size: 1.25rem;
  transition: color 0.3s;
}

.project-description-link:hover {
  color: #ffc107;
}

/* latest css */
.project-card {
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: transform 0.3s;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-pic {
  width: 100%;
  height: auto;
  display: block;
}

.project-description {
  position: absolute;
  top: 50%;
  left: 40px;
  /* width: 100%; */
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s;
  background-color: #1c1d25;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.project-card:hover .project-description {
  opacity: 1;
}

.project-description-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.project-description-sub-title {
  font-size: 1rem;
  color: #888;
  margin-bottom: 0.5rem;
}

.project-description-para {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.project-description-links {
  margin-top: auto;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.project-description-link {
  color: #fff;
  font-size: 1.25rem;
  transition: color 0.3s;
}

.project-description-link:hover {
  color: #ffc107;
}



